import React from 'react'
import { FaArrowAltCircleUp, FaRegArrowAltCircleUp, FaSlidersH, FaThList, FaCompressArrowsAlt, FaExpandArrowsAlt, FaAngleUp, FaAngleDown, } from 'react-icons/fa'

import PanelHeader from '../../../elem/panel/PanelHeader'
import { IconTooltip as Tooltip } from '../../../elem/Tooltip'

export default ({ 
    name,
    filterExpanded,
    toggleFilter,
    togglePromotedRecords,
    promotedRecords,
    setCollapsed,
    isCollapsed,
    isExpanded,
    setExpandedMenu,
}) => {
    return (
        <PanelHeader extraClass={'wellPanelHeader'}>
            <div className="panelHeaderText">Station Explorer</div>
            <div className="panelHeaderControls">
                <div className="panelHeaderIconWrapper">
                    {!filterExpanded && (
                        <div
                            className="panelHeaderIconDiv"
                            data-tip={`${
                                promotedRecords
                                    ? `Do not promote Selected Records`
                                    : `Promote Selected Records`
                            }`}
                            data-for={`well-promoted-records-toggle`}
                            onClick={() =>
                                togglePromotedRecords(!promotedRecords, 'well')
                            }
                        >
                            {promotedRecords ? (
                                <FaArrowAltCircleUp />
                            ) : (
                                <FaRegArrowAltCircleUp />
                            )}
                            <Tooltip
                                id={`well-promoted-records-toggle`}
                            />
                        </div>
                    )}
                    <div
                        className="panelHeaderIconDiv"
                        data-tip={`${
                            filterExpanded
                                ? 'View Well List'
                                : 'View Well Filter'
                        }`}
                        data-for={`well-view-toggle`}
                        onClick={() => toggleFilter(!filterExpanded)}
                    >
                        {filterExpanded ? <FaThList /> : <FaSlidersH />}
                        <Tooltip id={'well-view-toggle'} />
                    </div>
                   {isExpanded ? null : (
                        <div
                            className="panelHeaderIconDiv"
                            data-tip={`Collapse / Expand`}
                            data-for={`sample-panel-toggle`}
                            onClick={() => setCollapsed(!isCollapsed)}
                        >
                            {isCollapsed ? <FaAngleUp /> : <FaAngleDown />}
                            <Tooltip
                                id={`sample-panel-toggle`}
                            />
                        </div>)
                    }
                    <div
                        className="panelHeaderIconDiv"
                        data-tip={`${
                            isExpanded
                                ? `Collapse`
                                : `Expand to Full Screen`
                        }`}
                        data-for={`sample-expand-toggle`}
                        onClick={() => setExpandedMenu(current => current === name ? null : name)}
                    >
                        {isExpanded ? <FaCompressArrowsAlt /> : <FaExpandArrowsAlt />}
                        <Tooltip id={`sample-expand-toggle`} />
                    </div>
                </div>
            </div>
        </PanelHeader>
    )
}
