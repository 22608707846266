import React, { useContext } from 'react'
import { FaAngleUp, FaAngleDown, FaCompressArrowsAlt, FaExpandArrowsAlt, } from 'react-icons/fa'

import { IconContext } from 'react-icons'
import { IconTooltip as Tooltip } from '../../../elem/Tooltip'
import PanelHeader from '../../../elem/panel/PanelHeader'
import { DataContext } from './DataContext'
import ResetDropDownButton from '../../../elem/chart/ResetDropDownButton'
import ParamSelectorButton from '../../../elem/chart/ParamSelectorButton'
import { AppStateContext } from '../AppStateContext'
import ExpandButton from '../../../elem/list/ExpandButton'

export default () => {
    const {
        displayParamWindowDropdown,
        toggleParamWindowDropdown,
        paramList,
        setCollapsed,
        isCollapsed,
        resetExpanded, 
        toggleResetExpanded,
    } = useContext(DataContext)
    const { expandedMenu, setExpandedMenu } = useContext(AppStateContext)

    return (
        <PanelHeader extraClass={'aggregatePanelHeader'}>
            <div className="panelHeaderText">Aggregate Chemistry Sample Results Chart</div>
            <div className="panelButtons">
                <ResetDropDownButton 
                    resetExpanded={resetExpanded} 
                    toggleResetExpanded={toggleResetExpanded}
                />
                {/* <ResetZoomButton resetFunction={resetZoom} /> */}
                {paramList && paramList.length ? (
                    <ParamSelectorButton
                        displayParamWindowDropdown={
                            displayParamWindowDropdown
                        }
                        toggleParamWindowDropdown={
                            toggleParamWindowDropdown
                        }
                    />
                ) : null}
                {/* <TimeWindowSelectorButton
                    timeWindow={timeWindow}
                    displayTimeWindowDropdown={displayTimeWindowDropdown}
                    toggleTimeWindowDropdown={toggleTimeWindowDropdown}
                /> */}
                <div className="panelHeaderIconWrapper">
                    {expandedMenu === 'SampleAggregateChart' ? null : (
                        <div
                            className="panelHeaderIconDiv"
                            data-tip={`Collapse / Expand`}
                            data-for={`sample-aggregate-panel-collapse`}
                            onClick={() =>
                                setCollapsed(!isCollapsed)
                            }
                        >
                            <IconContext.Provider
                                value={{
                                    className: `panelHeaderIcon`,
                                }}
                            >
                                { isCollapsed 
                                    ? <FaAngleUp /> 
                                    : <FaAngleDown /> 
                                }
                            </IconContext.Provider>
                            <Tooltip id={'sample-aggregate-panel-collapse'} />
                        </div>
                    )}
                    <ExpandButton 
                        name={`SampleAggregateChart`}
                        expandedMenu={expandedMenu}
                        setExpandedMenu={setExpandedMenu}
                    />            
                </div>
            </div>
        </PanelHeader>
    )
}
