import React, { createContext, useCallback, useState, useContext, useMemo } from 'react'
import { ParameterContext } from '../../wrappers/ParameterContext'

const AppStateContext = createContext(null)

const AppStateContextProvider = ({ children }) => {
    const { params, setParams } = useContext(ParameterContext)

    const [promotedRecords, setPromotedRecords] = useState({'sample': false, 'well': false, })
    const [expandedMenu, setExpandedMenu] = useState(null)

    const [detailState, setDetailState] = useState({
        well: {
            visible: false,
            facilityID: null,
        }
    })

    const [filterState, setFilterState] = useState({
        well: false,
        sample: false
    })

    const [mapState, setMapState] = useState({
        selectedFeatures: [],
        allFeatures: [],
        mapSelectionIds: "",
        map: null
    })

    const updateDetailState = (name, newState) => {        
        const newDetailState = {
            [name]: {
                ...detailState[name],
                ...newState,
            },
        }
        setDetailState({
            ...detailState,
            ...newDetailState,
        })
    }

    const updateFilterState = (name, newState) => {
        setFilterState({
            ...filterState,
            [name]: newState
        })
    }

    const syncFilterState = newState => {
        const newFilters = Object.keys(filterState).map(filter => ({[filter]: newState}))
        setFilterState(newFilters)
    }

    const allExplorersPromoted = useMemo(() => {
        return Object.values(promotedRecords).every(e => e === true)
    }, [promotedRecords])

    const togglePromotedRecords = useCallback((promoted, field) => {
        const newParams = {
            ...params.app,
            [`${field}Promoted`]: promoted
        }
        setPromotedRecords({...promotedRecords, [field]: promoted})
        setParams(newParams, 'app')
    }, [params, promotedRecords])

    const promoteAllExplorers = useCallback((promoted) => {
        const newParams = {
            ...params.app,
            [`samplePromoted`]: promoted,
            [`wellPromoted`]: promoted,
        }
        setPromotedRecords({
            ...promotedRecords, 
            [`well`]: promoted,
            [`sample`]: promoted,
        })
        setParams(newParams, 'app')
    }, [params, promotedRecords])

    return (
        <AppStateContext.Provider
            value={{
                detailState,
                updateDetailState,
                mapState,
                setMapState,
                filterState,
                updateFilterState,
                syncFilterState,
                promotedRecords, 
                togglePromotedRecords,
                promoteAllExplorers,
                allExplorersPromoted,
                expandedMenu,
                setExpandedMenu,
            }}
        >
            {children}
        </AppStateContext.Provider>
    )
}

export { AppStateContext }
export default AppStateContextProvider
